
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ElementOverview extends Vue {
  @Prop({ default: false }) private showOverview!: boolean
  @Watch('showOverview')
  dataUpdate (val: boolean) {
    if (val) {
      this.loadData()
    }
  }

  private cycleList = ['日', '周', '月']
  private riskLevelList = []
  private list = []
  private loading = false

  created (): void {
    this.loadRiskLevelList()
  }

  loadRiskLevelList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'riskLevel'
    }).then(res => {
      this.riskLevelList = res.riskLevel || []
    })
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.patrol.selectElementViewList).then(res => {
      this.list = res || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 关闭
  closeDialog () {
    this.$emit('update:showOverview', false)
  }
}
