
import { Component, Vue } from 'vue-property-decorator'
import Overview from './Overview.vue'
import { KeepAlive } from '../../../utils/decorators'

@Component({
  components: { Overview }
})
@KeepAlive
export default class ElementList extends Vue {
  private searchForm: { elementName: string; riskLevel: string } = {
    elementName: '',
    riskLevel: ''
  }

  private riskLevelList = []
  private elementList = []
  private loading = false
  private showOverview = false

  page = 1
  size = 10
  total = 0

  created (): void {
    this.loadRiskLevelList()
    this.loadData()
  }

  loadRiskLevelList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'riskLevel'
    }).then(res => {
      this.riskLevelList = res.riskLevel || []
    })
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.patrol.selectElementByPage, {
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.elementList = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onAdd () {
    this.$router.push({ name: 'patrolElementAdd' })
  }

  onEdit (elementId: string) {
    this.$router.push({ name: 'patrolElementEdit', params: { elementId } })
  }

  onDetail (elementId: string) {
    this.$router.push({ name: 'patrolElementDetail', params: { elementId } })
  }

  onDelete (elementId: string) {
    this.$confirm('确认删除该要素, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.patrol.deleteElement, {
        elementId
      }).then(() => {
        this.loadData()
      })
    })
  }
}
